<template>
  <div class="app-container">
    <p class="switch-api" @click="switchMd">
      {{ switchFlag ? "查看老版api文档" : "查看新版api文档" }}
    </p>
    <div>
      <el-tree
        class="tree"
        ref="tree"
        node-key="uuid"
        :data="treeData"
        :props="defaultProps"
        default-expand-all
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div @click="toDiv(data.uuid, data.height)">{{ data.text }}</div>
        </div>
      </el-tree>
      <div class="markdown-container">
        <div class="markdown-body">
          <newapi v-if="switchFlag"></newapi>
          <oldapi v-else></oldapi>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "highlight.js/styles/github.css";
import hljs from "highlight.js";
import newapi from "@/assets/md/newapi.md";
import oldapi from "@/assets/md/oldapi.md";
export default {
  components: {
    newapi,
    oldapi
  },
  data() {
    return {
      treeData: [],
      oldTreeData: [],
      defaultProps: { label: "text", children: "children" },
      switchFlag: true
    };
  },
  watch: {
    switchFlag() {
      this.getCatalog();
      hljs.highlightAll();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getCatalog();
      hljs.highlightAll();
    });
  },
  methods: {
    getCatalog() {
      const h = ["h1", "h2", "h3", "h4", "h5", "h6"];
      this.$nextTick(() => {
        var elements = $(":header");
        let hElements = [];
        for (const key of elements) {
          if (h.indexOf(key.localName) > -1) {
            let text;
            if (key.children && key.children.length) {
              text = this.getText(key.children);
            } else {
              text = key.innerHTML;
            }
            $(key).attr(
              "id",
              "id" + parseInt(key.localName[1]) + parseInt(Math.random() * 100)
            );
            hElements.push({
              hLevel: parseInt(key.localName[1]),
              text,
              id: key.localName,
              uuid: key.id,
              height: $(key).offset().top
            });
          }
        }

        let result = this.toTree(hElements);
        this.treeData = result;
        // 目录默认选中第一个
        if (!result) return;
        this.treeData = result;
        this.$refs.tree.setCurrentKey(result[0].uuid);
      });
    },
    toTree(flatArr) {
      var tree = [];
      var copyArr = flatArr.map(function(item) {
        return item;
      });

      // 根据指定级别查找该级别的子孙级，并删除掉已经查找到的子孙级
      var getChildrenByLevel = function(currentLevelItem, arr, level) {
        if (!currentLevelItem) {
          return;
        }
        // 将level值转成负数，再进行比较
        var minusCurrentLevel = -currentLevelItem.hLevel;
        var children = [];
        for (var i = 0, len = arr.length; i < len; i++) {
          var levelItem = arr[i];
          if (-levelItem.hLevel < minusCurrentLevel) {
            children.push(levelItem);
          } else {
            // 只找最近那些子孙级
            break;
          }
        }
        // 从数组中删除已经找到的那些子孙级，以免影响到其他子孙级的查找
        if (children.length > 0) {
          arr.splice(0, children.length);
        }
        return children;
      };

      var getTree = function(result, arr, level) {
        // 首先将数组第一位移除掉，并添加到结果集中
        var currentItem = arr.shift();

        currentItem.level = level;
        result.push(currentItem);
        while (arr.length > 0) {
          if (!currentItem) {
            return;
          }
          // 根据当前级别获取它的子孙级
          var children = getChildrenByLevel(currentItem, arr, level);
          // 如果当前级别没有子孙级则开始下一个
          if (children.length == 0) {
            currentItem = arr.shift();
            currentItem.level = level;
            if (currentItem) {
              result.push(currentItem);
            }
            continue;
          }
          currentItem.children = [];
          // 查找到的子孙级继续查找子孙级
          getTree(currentItem.children, children, level + 1);
        }
      };
      getTree(tree, copyArr, 1);
      return tree;
    },
    getText(arr) {
      let result = null;
      if (!arr.length) return;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length) {
          result = this.getText(arr[i].children);
        } else {
          result = arr[i].innerHTML;
        }
      }

      return result;
    },
    toDiv(uuid, height) {
      document.getElementById(uuid).scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    },
    switchMd() {
      this.switchFlag = !this.switchFlag;
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>
<style src="../../assets/css/github-markdown-light.css">

</style>
<style scoped lang="scss">
.tree {
  position: fixed;
  top: 140px;
  left: 20px;
  width: 200px;

  ::v-deep .custom-tree-node {
    cursor: pointer;
  }
}

.markdown-container {
  ::v-deep .markdown-body {
    width: 1200px;
    margin: 0 auto;

    table {
      display: inline-table;
      width: 100%;
      // thead,tbody,tr{
      //   width: 100%;
      // }
    }
  }
}

.hljs-line-numbers {
  text-align: right;
  //以下3行要加 !important 来避免样式覆盖
  border-right: 1px solid #ccc !important;
  margin-right: 10px !important;
  padding-right: 5px !important;
  color: #999;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 1500px) {
  .markdown-container {
    ::v-deep .markdown-body {
      width: 900px;
    }
  }
}

@media (max-width: 1200px) {
  .markdown-container {
    ::v-deep .markdown-body {
      width: 700px;
    }
  }
}

.switch-api {
  position: fixed;
  top: 100px;
  left: 20px;
  cursor: pointer;
}
</style>
